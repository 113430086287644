import React, {useRef, useState} from 'react';
import {Box, Container, Grid, Hidden, Tab, Tabs, Typography} from '@mui/material';
import {Image} from 'mui-image';
import bgVision from '../bg_vision.svg';
import bgDownload from '../bg_download.svg';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/system';

const features = [
  {
    icon: '/image/img_feature_001.svg',
    title: '零佣金，高賺益',
    description: '不收取任何運費佣金，零抽成，告別12-15%高額抽成。'
  },
  {
    icon: '/image/img_feature_002.svg',
    title: '智能媒合',
    description: '根據時間和路線，透過AI演算法推薦所有合適的貨運需求單。'
  },
  {
    icon: '/image/img_feature_003.svg',
    title: '透明定價',
    description: '開放式報價，雙方自主議價協商。'
  },
  {
    icon: '/image/img_feature_004.svg',
    title: '彈性調度',
    description: '不擔心臨時需要額外車輛。'
  },
  {
    icon: '/image/img_feature_005.svg',
    title: '信用評分',
    description: '雙向評價機制激勵優質服務。每筆訂單都能累積雙方評價與星等，讓合作更放心。'
  },
  {
    icon: '/image/img_feature_006.svg',
    title: '安全可靠',
    description: '嚴格身份驗證，電子簽署有保障。'
  }
]

function Home() {

  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const downloadSectionRef = useRef(null);

  const isApple = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      console.log("This is an iOS device.");
      return true;
    } else {
      console.log("This is not an iOS device!");
      return false;
    }
  }

  return (
    <Container disableGutters maxWidth={false} sx={{overflowX: 'hidden'}}>
      <Box sx={{ position: 'fixed', right: 20, bottom: 20, zIndex: 1000}}
           onClick={()=> downloadSectionRef.current?.scrollIntoView({behavior: 'smooth'})}
      >
        <Image width={'100%'} src={isApple()? "/image/img_anchor_ios.svg" : "/image/img_anchor_android.svg"} alt={'download_anchor'}/>
      </Box>
      <Box style={{width: '100%'}}>
        <img width={'100%'} src="/image/bg_hero_2.svg" alt={'bg_hero_2'}/>
        <img src="/image/img_logo2.svg" alt={'img_logo2'} style={{position: 'absolute', left: 100, top: 30}}/>
      </Box>
      <Container maxWidth={'sm'} sx={{mt: sm ? -4 : lg ? -20 : -40}}>
        <Image src="/image/img_hero_subtitle.svg" alt={'img_hero_subtitle'}/>
        <Box height={20}/>
        <Image src="/image/img_hero_title.svg" alt={'img_hero_title'}/>
      </Container>
      <Container maxWidth={'lg'} sx={{ mt: 10}}>
        <Grid container alignItems={'center'} justifyItems={'center'}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">你是否遇到... 這些貨運困擾？</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Image fit src="/image/img_pain_point.svg" alt={'img_pain_point'} style={{marginTop: 40}}/>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={'lg'}>
        <img width='100%' src="/image/img_divider.svg" alt={'img_divider'}/>
      </Container>
      <Container style={{
        marginTop: '40px',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
      }}>
        <Grid container spacing={10} alignItems={'center'} justifyItems={'center'}>
          <Grid item xs={12} sm={6}>
            <img width={'100%'} src="/image/img_feature_1.svg" alt={'img_feature_1'} style={{marginTop: 40}}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography style={{color: '#212B49', fontSize: 38}}>告別煩惱</Typography>
            <Typography style={{color: '#212B49', fontSize: 38}}>這就是你的解決方案！</Typography>
            <Typography style={{color: '#1A64EC', fontSize: 22}}>引領貨運新時代的利器——嘟拉古APP強勢來襲！</Typography>
            <Typography style={{color: '#434D6C'}}>
              嘟拉古貨運媒合平台匯集大量貨運需求及貨車司機。
              系統自動通知相似行程的給司機決定
              無論您是需要貨車，還是有空車想接單，這裡是您的最佳選擇！
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{
        backgroundColor: '#00C2FF',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        position: 'relative',
        mt: 10
      }}>
        <img src="/image/img_cross_white.svg" alt={'img_cross_white'}
             style={{position: 'absolute', right: 0, top: 30}}/>
        <Typography style={{color: '#fff', fontSize: 38, marginTop: 200}}>6大優勢，全台據點服務</Typography>
        <Typography style={{
          color: '#fff',
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center'
        }}>您不必再為高抽成與臨時調度煩惱，而只需透過平台媒合，享受透明定價與安全可靠的服務，輕鬆完成每筆運輸需求。</Typography>
        <Box maxWidth={'md'} mt={6}>
          <Grid container gap={0}>
            {features.map((el, index) =>
              <Grid children xs={12} sm={4}>
                <Box margin={1} key={index} sx={{boxShadow: 2}} borderRadius={2}
                     style={{backgroundColor: '#fff', padding: 20, height: 230}}>
                  <img src={el.icon} alt={'icon'}/>
                  <Typography style={{color: '#0350DF', fontSize: 22}}>0{index + 1}</Typography>
                  <Typography style={{fontSize: 22}}>{el.title}</Typography>
                  <Typography style={{color: '#434D6C', fontSize: 16}}>{el.description}</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box height={100}/>
      </Box>

      <Box style={{
        width: '100vw',
        backgroundImage: `url(${bgVision})`,
        backgroundSize: 'cover',
      }}>
        <Container>
          <Grid container alignItems={'center'} justifyItems={'center'}>
            <Grid item xs={12} md={8}>
              <Box height={200}/>
              <Typography style={{color: '#ffffff', fontSize: 38}}>We are Doolaku</Typography>
              <Typography style={{color: '#ffffff', fontSize: 38}}>解決貨運市場的封閉與挑戰</Typography>
              <Typography style={{
                color: '#ffffff',
                fontSize: 20
              }}>有了嘟拉古，司機不再為車趟煩惱，業主隨時找到可靠的貨車資源。</Typography>
              <Box height={200}/>
            </Grid>
            <Grid item xs={12} md={4}>

            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box style={{
        marginTop: '100px',
        backgroundSize: 'cover',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center"
      }}>
        <Typography style={{color: '#0350DF', fontSize: 38}}>輕鬆上手</Typography>

        <Tabs
          value={currentTab}
          onChange={() => setCurrentTab(currentTab === 0 ? 1 : 0)}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="我是司機"/>
          <Tab label="我是業主"/>
        </Tabs>

        {
          currentTab === 0 && (
            <Container sx={{p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Box borderRadius={2} border={1} borderColor={'#0350DF'} p={2}>
                <Typography style={{color: '#0350DF', fontSize: 20}}>司機版</Typography>
                <Box display={'flex'} alignItems={'center'}>
                  <img src={"/image/ic_props.svg"} alt={'ic_props'}/>
                  <Typography>抓緊免費試用</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <img src={"/image/ic_props.svg"} alt={'ic_props'}/>
                  <Typography>未來的低額月費讓你繼續受益</Typography>
                </Box>
              </Box>
              <Box height={40}/>
              <Grid container spacing={3} justifyContent="center">
                <Hidden mdDown>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Typography variant="h1">1</Typography>
                      <Typography variant="h4">登錄近期行程</Typography>
                      <Typography variant="body2" color="textSecondary">
                        填寫好未來兩週既有的行程，系統將通知您符合路線的任務。
                      </Typography>
                      <Image src={"/image/img_driver_sc2.svg"} alt={'img_driver_sc2'}/>
                    </Box>
                    <Box mt={10}>
                      <Typography variant="h1">3</Typography>
                      <Typography variant="h4">靈活議價接單</Typography>
                      <Typography variant="body2" color="textSecondary">
                        選擇接單，評估後向業主提出最合適的報價。
                      </Typography>
                      <Image src={"/image/img_driver_sc1.svg"} alt={'img_driver_sc1'}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Image src="/image/img_driver_main.svg" alt={'img_driver_main'} width={'100%'}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box mt={10}>
                      <Typography variant="h1">2</Typography>
                      <Typography variant="h4">案源挑選</Typography>
                      <Typography variant="body2" color="textSecondary">
                        透過接單列表，您可以檢視行程及業主評價，幫助您做出最佳選擇。
                      </Typography>
                      <Image src="/image/img_driver_sc3.svg" alt="img_driver_sc3" width={'100%'}/>
                    </Box>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Image src="/image/img_driver_main.svg" alt={'img_driver_main'} width={'100%'}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Typography variant="h1">1</Typography>
                      <Typography variant="h4">登錄近期行程</Typography>
                      <Typography variant="body2" color="textSecondary">
                        透過接單列表，您可以檢視行程及業主評價，幫助您做出最佳選擇。
                      </Typography>
                    </Box>
                    <Box mt={10}>
                      <Typography variant="h1">2</Typography>
                      <Typography variant="h4">案源挑選</Typography>
                      <Typography variant="body2" color="textSecondary">
                        我們將為您提供多個回頭車選項，根據您的需求匹配最合適的運輸資源。
                      </Typography>
                      <Box mt={4}/>
                      <Typography variant="body2" color="textSecondary">
                        每位司機的評價一目了然，幫助您做出最佳選擇。
                      </Typography>
                      <Image src="/image/img_driver_sc3.svg" alt="img_driver_sc3" width={'100%'}/>
                    </Box>
                    <Box mt={10}>
                      <Typography variant="h1">3</Typography>
                      <Typography variant="h4">靈活議價接單</Typography>
                      <Typography variant="body2" color="textSecondary">
                        選擇接單，評估後向業主提出最合適的報價。
                      </Typography>
                      <Image src={"/image/img_driver_sc1.svg"} alt={'img_driver_sc1'}/>
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          )
        }

        {
          currentTab === 1 && (
            <Container sx={{p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Box borderRadius={2} border={1} borderColor={'#0350DF'} p={2}>
                <Typography style={{color: '#0350DF', fontSize: 20}}>業主版</Typography>
                <Box display={'flex'} alignItems={'center'}>
                  <img src={"/image/ic_props.svg"} alt={'ic_props'}/>
                  <Typography>永久免費</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <img src={"/image/ic_props.svg"} alt={'ic_props'}/>
                  <Typography>立即享有全方位貨運媒合服務</Typography>
                </Box>
              </Box>
              <Box height={40}/>
              <Grid container spacing={3} justifyContent="center">
                <Hidden mdDown>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Typography variant="h1">1</Typography>
                      <Typography variant="h4">發起訂單</Typography>
                      <Typography variant="body2" color="textSecondary">
                        依照需求填入地點、時間、載運貨物與預算。
                      </Typography>
                      <Image src={"/image/img_boss_sc1.svg"} alt={'img_boss_sc1'}/>
                    </Box>
                    <Box mt={10}>
                      <Typography variant="h1">3</Typography>
                      <Typography variant="h4">貨車動態</Typography>
                      <Typography variant="body2" color="textSecondary">
                        貨物配送狀態通知，即時掌握取貨、到貨狀況。
                      </Typography>
                      <Image src={"/image/img_boss_sc3.svg"} alt={'img_boss_sc3'}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Image src="/image/img_boss_main.svg" alt={'img_boss_main'} width={'100%'}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box mt={10}>
                      <Typography variant="h1">2</Typography>
                      <Typography variant="h4">選擇司機</Typography>
                      <Typography variant="body2" color="textSecondary">
                        審核司機報價並查看其評價，選擇出最合適的司機。
                      </Typography>
                      <Image src="/image/img_boss_sc2.svg" alt="img_boss_sc2" width={'100%'}/>
                    </Box>
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Image src="/image/img_boss_main.svg" alt={'img_boss_main'} width={'100%'}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box>
                      <Typography variant="h1">1</Typography>
                      <Typography variant="h4">發起訂單</Typography>
                      <Typography variant="body2" color="textSecondary">
                        依照需求填入地點、時間、載運貨物與預算。
                      </Typography>
                      <Box mt={4}/>
                      <Image src={"/image/img_boss_sc1.svg"} alt={'img_boss_sc1'}/>
                    </Box>
                    <Box mt={10}>
                      <Typography variant="h1">2</Typography>
                      <Typography variant="h4">選擇司機</Typography>
                      <Typography variant="body2" color="textSecondary">
                        審核司機報價並查看其評價，選擇出最合適的司機。
                      </Typography>
                      <Box mt={4}/>
                      <Typography variant="body2" color="textSecondary">
                        每位司機的評價一目了然，幫助您做出最佳選擇。
                      </Typography>
                      <Image src="/image/img_boss_sc2.svg" alt="img_boss_sc2" width={'100%'}/>
                    </Box>
                    <Box mt={10}>
                      <Typography variant="h1">3</Typography>
                      <Typography variant="h4">貨車動態</Typography>
                      <Typography variant="body2" color="textSecondary">
                        貨物配送狀態通知，即時掌握取貨、到貨狀況。
                      </Typography>
                      <Image src="/image/screenshot-notify.png" alt="screenshot-profile" width={'100%'}/>
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          )
        }

      </Box>
      <Box style={{
        backgroundImage: `url(${bgDownload})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vw',
      }}>
        <Container maxWidth={'lg'} sx={{p: 4}}>
          <Grid container spacing={0} sx={{marginTop: 3}} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Image src="/image/img_both_sc.svg" alt="img_both_sc" width={'100%'}/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color={'white'} style={{fontSize: 40}}>現在就行動，立即加入</Typography>
              <Typography color={'white'} style={{fontSize: 20}}>是時候讓貨運變得簡單省心，享受無憂貨運體驗！</Typography>
              <Typography color={'white'}
                          style={{fontSize: 16}}>點擊下載，享受永久免費的業主版本和現行免費試用的司機版本</Typography>
              <Typography color={'white'}
                          style={{fontSize: 16}}>探索便捷高效的貨運新時代。讓你的貨運事業邁向新的高峰！</Typography>
              <Grid container spacing={2} sx={{marginTop: 2, padding: 4}} ref={downloadSectionRef}>
                {
                  isApple() ? (
                    <>
                      <Grid item xs={6} sm={6} container justifyContent={'center'}>
                        <Typography variant="h6" align="center" color={'white'}>下載司機版</Typography>
                        <img src="/image/qrcode_ios_driver.png" alt="Apple Store" style={{width: '100%'}}/>
                        <img src="/image/download-apple.png" alt="Apple Store" style={{width: '100%', cursor: 'pointer'}}
                             onClick={() => {
                               window.open('https://apps.apple.com/tw/app/%E5%98%9F%E6%8B%89%E5%8F%A4%E5%9B%9E%E9%A0%AD%E8%BB%8A-%E5%8F%B8%E6%A9%9F%E7%89%88/id6648753810', '_blank');
                             }}/>
                      </Grid>
                      <Grid item xs={6} sm={6} container justifyContent={'center'}>
                        <Typography variant="h6" align="center" color={'white'}>下載業主版</Typography>
                        <img src="/image/qrcode_ios_boss.png" alt="Apple Store" style={{width: '100%'}}/>
                        <img src="/image/download-apple.png" alt="Apple Store" style={{width: '100%', cursor: 'pointer'}}
                             onClick={() => {
                               window.open('https://apps.apple.com/tw/app/%E5%98%9F%E6%8B%89%E5%8F%A4%E5%9B%9E%E9%A0%AD%E8%BB%8A-%E6%A5%AD%E4%B8%BB%E7%89%88/id6648753783', '_blank');
                             }}/>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} container justifyContent={'center'}>
                        <Typography variant="h6" align="center" color={'white'}>下載司機版</Typography>
                        <img src="/image/qrcode-android-driver.png" alt="Google Play" style={{width: '100%'}}/>
                        <img src="/image/download-android.png" alt="Google Play" style={{width: '100%', cursor: 'pointer'}}
                             onClick={() => {
                               window.open('https://play.google.com/store/apps/details?id=com.doolaku.driver', '_blank');
                             }}/>
                      </Grid>
                      <Grid item xs={12} sm={6} container justifyContent={'center'}>
                        <Typography variant="h6" align="center" color={'white'}>下載業主版</Typography>
                        <img src="/image/qrcode-android-boss.png" alt="Google Play" style={{width: '100%'}}/>
                        <img src="/image/download-android.png" alt="Google Play" style={{width: '100%', cursor: 'pointer'}}
                             onClick={() => {
                               window.open('https://play.google.com/store/apps/details?id=com.doolaku.boss', '_blank');
                             }}/>
                      </Grid>
                    </>
                  )
                }

              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}

export default Home;
